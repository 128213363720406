<template>
  <div>
    <v-skeleton-loader
      :elevation="2"
      type="card"
      v-show="!loaded"
    ></v-skeleton-loader>
  </div>
  <div v-show="loaded">
    <!-- TOOLBAR -->
    <div class="toolbar" v-if="editor && canUseEditor">
      <v-row class="px-4" justify="space-between" align="center">
        <v-col class="d-flex align-center" cols="auto">
          <v-select
            class="heading-combo mr-2 pt-5"
            density="compact"
            variant="outlined"
            label="Párrafo"
            v-model="headingSelected"
            :items="headingItems"
            item-title="value"
            item-value="id"
            return-object
          >
            <template #item="{ item }">
              <v-list-item @click="selectItem(item)">
                <span :style="item.raw.style">{{
                  item.title
                }}</span></v-list-item
              >
            </template>
          </v-select>

          <v-tooltip location="top">
            <template #activator="{ props }">
              <v-btn
                icon
                @click="toggleBold"
                variant="text"
                size="small"
                :class="{ 'is-active': editor.isActive('bold') }"
                v-bind="props"
              >
                <v-icon>{{ boldIcon }}</v-icon>
              </v-btn>
            </template>
            <span>Negrita</span>
          </v-tooltip>

          <v-tooltip location="top">
            <template #activator="{ props }">
              <v-btn
                icon
                variant="text"
                size="small"
                @click="toggleItalic"
                :class="{ 'is-active': editor.isActive('italic') }"
                v-bind="props"
              >
                <v-icon>{{ italicIcon }}</v-icon>
              </v-btn>
            </template>
            <span>Cursiva</span>
          </v-tooltip>

          <v-tooltip location="top">
            <template #activator="{ props }">
              <v-btn
                icon
                variant="text"
                size="small"
                @click="toggleUnderline"
                :class="{ 'is-active': editor.isActive('underline') }"
                v-bind="props"
              >
                <v-icon>{{ underlineIcon }}</v-icon>
              </v-btn>
            </template>
            <span>Subrayado</span>
          </v-tooltip>

          <v-tooltip location="top">
            <template #activator="{ props }">
              <v-btn
                icon
                variant="text"
                size="small"
                @click="toggleStrike"
                :class="{ 'is-active': editor.isActive('strike') }"
                v-bind="props"
              >
                <v-icon>{{ strikeIcon }}</v-icon>
              </v-btn>
            </template>
            <span>Tachado</span>
          </v-tooltip>

          <v-divider vertical class="mx-2"></v-divider>

          <!-- COLOR TEXTO -->
          <v-menu
            v-model="showColorText"
            :close-on-content-click="false"
            :nudge-width="200"
            offset-y
          >
            <template #activator="{ props }">
              <v-btn
                icon
                variant="text"
                size="small"
                @click="onClickColorText"
                v-bind="props"
                :class="{ 'is-active': editor.isActive('textStyle') }"
              >
                <v-icon>{{ colorTextIcon }}</v-icon>
              </v-btn>
            </template>
            <ColorPicker
              :title="'Color de texto'"
              v-if="showColorText"
              @mouseleave="showColorText = false"
              @onSelect="onColorSelect($event, 'colorText')"
              @onClear="
                editor.chain().focus().unsetColor().run(),
                  (showColorText = false)
              "
            />
          </v-menu>

          <!-- COLOR RELLENO -->
          <v-menu
            v-model="showColorFill"
            :close-on-content-click="false"
            :nudge-width="200"
            offset-y
          >
            <template #activator="{ props }">
              <v-btn
                icon
                variant="text"
                size="small"
                @click="onClickColorFill"
                v-bind="props"
                :class="{ 'is-active': editor.isActive('highlight') }"
              >
                <v-icon>{{ colorFillIcon }}</v-icon>
              </v-btn>
            </template>
            <ColorPicker
              :title="'Color de relleno'"
              v-if="showColorFill"
              @onSelect="onColorSelect($event, 'colorFill')"
              @onClear="
                editor.chain().focus().unsetHighlight().run(),
                  (showColorFill = false)
              "
            />
          </v-menu>

          <v-divider vertical class="mx-2"></v-divider>

          <!-- ALINEAR -->
          <v-menu offset-y v-model="menuAlinear">
            <template #activator="{ props }">
              <v-tooltip location="top">
                <template #activator="{ props: tooltipProps }">
                  <v-btn
                    icon
                    variant="text"
                    size="small"
                    v-bind="{ ...props, ...tooltipProps }"
                    :class="{
                      'is-active':
                        editor.isActive({ textAlign: 'justify' }) ||
                        editor.isActive({ textAlign: 'left' }) ||
                        editor.isActive({ textAlign: 'right' }) ||
                        editor.isActive({ textAlign: 'center' }),
                    }"
                  >
                    <v-icon>{{ alignLeftIcon }}</v-icon>
                  </v-btn>
                </template>
                <span>Alinear</span>
              </v-tooltip>
            </template>
            <v-card>
              <v-row class="pa-2">
                <v-btn
                  icon
                  variant="text"
                  size="small"
                  @click="setTextAlign('left')"
                  :class="{
                    'is-active': editor.isActive({ textAlign: 'left' }),
                  }"
                >
                  <v-icon>{{ alignLeftIcon }}</v-icon>
                </v-btn>
                <v-btn
                  icon
                  variant="text"
                  size="small"
                  @click="setTextAlign('center')"
                  :class="{
                    'is-active': editor.isActive({ textAlign: 'center' }),
                  }"
                >
                  <v-icon>{{ alignCenterIcon }}</v-icon>
                </v-btn>
                <v-btn
                  icon
                  variant="text"
                  size="small"
                  @click="setTextAlign('right')"
                  :class="{
                    'is-active': editor.isActive({ textAlign: 'right' }),
                  }"
                >
                  <v-icon>{{ alignRightIcon }}</v-icon>
                </v-btn>
                <v-btn
                  icon
                  variant="text"
                  size="small"
                  @click="setTextAlign('justify')"
                  :class="{
                    'is-active': editor.isActive({ textAlign: 'justify' }),
                  }"
                >
                  <v-icon>{{ alignJustifyIcon }}</v-icon>
                </v-btn>
              </v-row>
            </v-card>
          </v-menu>

          <v-divider vertical class="mx-2"></v-divider>

          <!-- TABULAR -->
          <v-tooltip location="top">
            <template #activator="{ props }">
              <v-btn
                variant="text"
                size="small"
                icon
                @click="indent"
                v-bind="props"
              >
                <v-icon>{{ indentIcon }}</v-icon>
              </v-btn>
            </template>
            <span>Tabular a la derecha</span>
          </v-tooltip>

          <v-tooltip location="top">
            <template #activator="{ props }">
              <v-btn
                variant="text"
                size="small"
                icon
                @click="outdent"
                v-bind="props"
              >
                <v-icon>{{ outdentIcon }}</v-icon>
              </v-btn>
            </template>
            <span>Tabular a la izquierda</span>
          </v-tooltip>

          <v-divider vertical class="mx-2"></v-divider>

          <!-- LISTAS -->
          <v-menu offset-y v-model="menuListas">
            <template #activator="{ props }">
              <v-tooltip location="top">
                <template #activator="{ props: tooltipProps }">
                  <v-btn
                    icon
                    variant="text"
                    size="small"
                    v-bind="{ ...props, ...tooltipProps }"
                    :class="{
                      'is-active':
                        editor.isActive('bulletList') ||
                        editor.isActive('orderedList') ||
                        editor.isActive('taskList'),
                    }"
                  >
                    <v-icon>{{ ulIcon }}</v-icon>
                  </v-btn>
                </template>
                <span>Listas</span>
              </v-tooltip>
            </template>
            <v-card>
              <v-row class="pa-2">
                <v-btn
                  icon
                  variant="text"
                  size="small"
                  @click="toggleBulletList"
                  :class="{ 'is-active': editor.isActive('bulletList') }"
                >
                  <v-icon>{{ ulIcon }}</v-icon>
                </v-btn>
                <v-btn
                  icon
                  variant="text"
                  size="small"
                  @click="toggleOrderedList"
                  :class="{ 'is-active': editor.isActive('orderedList') }"
                >
                  <v-icon>{{ olIcon }}</v-icon>
                </v-btn>
                <v-btn
                  icon
                  variant="text"
                  size="small"
                  @click="toggleTaskList"
                  :class="{ 'is-active': editor.isActive('taskList') }"
                >
                  <v-icon>{{ listCheckboxIcon }}</v-icon>
                </v-btn>
              </v-row>
            </v-card>
          </v-menu>

          <v-divider vertical class="mx-2"></v-divider>

          <!-- ENLACES -->
          <v-tooltip location="top">
            <template #activator="{ props }">
              <v-btn
                icon
                variant="text"
                size="small"
                @click="setLink"
                :class="{ 'is-active': editor.isActive('link') }"
                v-bind="props"
              >
                <v-icon>{{ linkIcon }}</v-icon>
              </v-btn>
            </template>
            <span>Enlaces</span>
          </v-tooltip>

          <!-- CITA -->
          <v-tooltip location="top">
            <template #activator="{ props }">
              <v-btn
                icon
                variant="text"
                size="small"
                @click="editor.chain().focus().toggleBlockquote().run()"
                :class="{ 'is-active': editor.isActive('blockquote') }"
                v-bind="props"
              >
                <v-icon>{{ quoteIcon }}</v-icon>
              </v-btn>
            </template>
            <span>Cita</span>
          </v-tooltip>

          <!-- LINEA HORIZONTAL -->
          <v-tooltip location="top">
            <template #activator="{ props }">
              <v-btn
                icon
                variant="text"
                size="small"
                @click="editor.chain().focus().setHorizontalRule().run()"
                v-bind="props"
              >
                <v-icon>{{ minusIcon }}</v-icon>
              </v-btn>
            </template>
            <span>Linea horizontal</span>
          </v-tooltip>

          <v-divider vertical class="mx-2"></v-divider>

          <!-- TABLAS -->
          <v-menu v-model="menuTablasGlobal" offset-y>
            <template #activator="{ props }">
              <v-tooltip location="top">
                <template #activator="{ props: tooltipProps }">
                  <v-btn
                    icon
                    variant="text"
                    size="small"
                    v-bind="{ ...props, ...tooltipProps }"
                    @click="onAddTableClick"
                  >
                    <v-icon>{{ addTableIcon }}</v-icon>
                  </v-btn>
                </template>
                <span>Tablas</span>
              </v-tooltip>
            </template>
            <v-card class="pa-2">
              <v-menu v-model="menuTablasInsert" offset-x>
                <template #activator="{ props }">
                  <div v-bind="props" class="d-flex justify-space-between">
                    <span>Insertar tabla</span>
                    <v-icon>{{ subMenuIcon }}</v-icon>
                  </div>
                </template>
                <div class="pa-2">
                  <v-row class="pa-0">
                    <v-col cols="12" class="d-flex align-start">
                      <v-checkbox
                        id="chk-with-header"
                        v-model="withHeaderRow"
                        @click="onChangeWithHeaderTable"
                        class="ma-0"
                      ></v-checkbox>
                      <label for="chk-with-header">Incluir cabecera</label>
                    </v-col>
                  </v-row>

                  <div class="cuadricula">
                    <div
                      v-for="(fila, rowIndex) in 7"
                      :key="rowIndex"
                      class="max-w"
                    >
                      <div
                        v-for="(cuadro, colIndex) in 7"
                        :key="colIndex"
                        :class="{
                          cuadrado: true,
                          hovered: isHovered(rowIndex, colIndex),
                        }"
                        @mouseover="resaltarFilaColumna(rowIndex, colIndex)"
                        @click="
                          onClickInsertarTabla(), (menuTablasGlobal = false)
                        "
                      ></div>
                    </div>
                  </div>
                  <v-row justify="end" class="px-4">
                    {{ qRow }} x {{ qCol }}
                  </v-row>
                </div>
              </v-menu>

              <v-divider class="my-2"></v-divider>

              <div
                :class="{ 'li-disabled': !hasTable }"
                @click="editor.chain().focus().addColumnBefore().run()"
              >
                <span>Agregar columna antes</span>
              </div>
              <div
                :class="{ 'li-disabled': !hasTable }"
                @click="editor.chain().focus().addColumnAfter().run()"
              >
                <span>Agregar columna después</span>
              </div>
              <div
                :class="{ 'li-disabled': !hasTable }"
                @click="editor.chain().focus().toggleHeaderColumn().run()"
              >
                <span>Convertir columna en cabecera</span>
              </div>
              <div
                :class="{ 'li-disabled': !hasTable }"
                @click="editor.chain().focus().deleteColumn().run()"
              >
                <span>Eliminar columna</span>
              </div>

              <v-divider class="my-2"></v-divider>

              <div
                :class="{ 'li-disabled': !hasTable }"
                @click="editor.chain().focus().addRowBefore().run()"
              >
                <span>Agregar fila antes</span>
              </div>
              <div
                :class="{ 'li-disabled': !hasTable }"
                @click="editor.chain().focus().addRowAfter().run()"
              >
                <span>Agregar fila después</span>
              </div>
              <div
                :class="{ 'li-disabled': !hasTable }"
                @click="editor.chain().focus().toggleHeaderRow().run()"
              >
                <span>Convertir fila en cabecera</span>
              </div>
              <div
                :class="{ 'li-disabled': !hasTable }"
                @click="editor.chain().focus().deleteRow().run()"
              >
                <span>Eliminar fila</span>
              </div>

              <v-divider class="my-2"></v-divider>

              <div
                :class="{ 'li-disabled': !hasTable }"
                @click="editor.chain().focus().mergeOrSplit().run()"
              >
                <span>Combinar/separar celdas</span>
              </div>

              <div
                :class="{ 'li-disabled': !hasTable }"
                @click="editor.chain().focus().toggleHeaderCell().run()"
              >
                <span>Convertir celda en cabecera</span>
              </div>

              <v-menu v-model="menuColorCelda" offset-x>
                <template #activator="{ props }">
                  <div v-bind="props" :class="{ 'li-disabled': !hasTable }">
                    <span>Color de celda</span>
                  </div>
                </template>
              </v-menu>
              <div @click="onChangecolorTable">
                <ColorPicker
                  :title="'Color de fondo'"
                  v-show="menuColorCelda"
                  @onSelect="onColorSelect($event, 'cellBgColor')"
                />
              </div>

              <v-menu v-model="menuBordesCelda" offset-x>
                <template #activator="{ props }">
                  <div v-bind="props" :class="{ 'li-disabled': !hasTable }">
                    <div class="d-flex justify-space-between">
                      <span>Bordes de celda</span>
                      <v-icon>{{ subMenuIcon }}</v-icon>
                    </div>
                  </div>
                </template>
                <div class="pa-2">
                  <div
                    v-for="(borde, idx) in borderStyles"
                    :key="idx"
                    @click="
                      editor
                        .chain()
                        .focus()
                        .setCellAttribute('borderStyle', borde.value)
                        .run()
                    "
                  >
                    {{ borde.name }}
                  </div>
                </div>
              </v-menu>

              <v-divider class="my-2"></v-divider>

              <div
                :class="{ 'li-disabled': !hasTable }"
                @click="editor.chain().focus().deleteTable().run()"
              >
                <span>Eliminar tabla</span>
              </div>
            </v-card>
          </v-menu>

          <v-divider vertical class="mx-2"></v-divider>

          <!-- IMAGENES -->
          <v-tooltip location="top" v-if="!hideImage">
            <template #activator="{ props }">
              <v-btn
                variant="text"
                size="small"
                icon
                @click="openModalImage"
                v-bind="props"
              >
                <v-icon>{{ imageIcon }}</v-icon>
              </v-btn>
            </template>
            <span>Agregar imágen</span>
          </v-tooltip>

          <v-divider vertical class="mx-2"></v-divider>

          <!-- DESHACER -->
          <v-tooltip location="top">
            <template #activator="{ props }">
              <v-btn
                icon
                variant="text"
                size="small"
                @click="undo"
                :disabled="!editor.can().chain().focus().undo().run()"
                v-bind="props"
              >
                <v-icon>{{ undoIcon }}</v-icon>
              </v-btn>
            </template>
            <span>Atrás</span>
          </v-tooltip>

          <!-- REHACER -->
          <v-tooltip location="top">
            <template #activator="{ props }">
              <v-btn
                icon
                variant="text"
                size="small"
                @click="redo"
                :disabled="!editor.can().chain().focus().redo().run()"
                v-bind="props"
              >
                <v-icon>{{ redoIcon }}</v-icon>
              </v-btn>
            </template>
            <span>Adelante</span>
          </v-tooltip>

          <v-divider vertical class="mx-2"></v-divider>

          <!-- LIMPIAR FORMATO -->
          <v-tooltip location="top">
            <template #activator="{ props }">
              <v-btn
                variant="text"
                size="small"
                icon
                @click="unsetAllMarks"
                v-bind="props"
              >
                <v-icon>{{ clearFormatIcon }}</v-icon>
              </v-btn>
            </template>
            <span>Limpiar formatos</span>
          </v-tooltip>

          <v-divider vertical class="mx-2"></v-divider>

          <!-- PREVISUALIZAR -->
          <v-tooltip location="top">
            <template #activator="{ props }">
              <v-btn
                icon
                variant="text"
                size="small"
                @click="displayPreview = !displayPreview"
                :class="{ 'is-active': displayPreview }"
                v-bind="props"
              >
                <v-icon>{{ previewIcon }}</v-icon>
              </v-btn>
            </template>
            <span>Previsualizar</span>
          </v-tooltip>

        </v-col>
      </v-row>
    </div>

    <!-- EDITOR -->
    <div class="text-editor-wrapper" @click="onClickEditor" v-if="canUseEditor">
      <EditorContent
        :editor="editor"
        class="my-text-editor"
        :id="`text-editor-${uniqueId}`"
      />
    </div>

    <!-- PREVIEW -->
    <div
      v-html="getContent"
      v-if="displayPreview || !canUseEditor"
      class="preview-container"
    ></div>

    <!-- INSERTAR IMAGEN -->
    <v-dialog v-model="showInputImage" max-width="500px">
      <AddImageModal
        v-if="showInputImage"
        @cancelar="showInputImage = false"
        @on-updated-file="onUpdatedFile"
        @insert-image-from-url="insertImageFromUrl"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import axios from "axios";
import { Editor, EditorContent } from "@tiptap/vue-3";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import ResizableImage from "./resizable-image";
import TaskList from "@tiptap/extension-task-list";
import TaskItem from "@tiptap/extension-task-item";
import TextAlign from "@tiptap/extension-text-align";
import { Color } from "@tiptap/extension-color";
import TextStyle from "@tiptap/extension-text-style";
import Highlight from "@tiptap/extension-highlight";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import rules from "@/utils/helpers/rules";
import ColorPicker from "./ColorPicker";
import Link from "@tiptap/extension-link";
import AddImageModal from "./AddImageModal.vue";
import { Indent } from "./indent.js";
import { Text } from "@tiptap/extension-text";
import Paragraph from "@tiptap/extension-paragraph";

const CustomText = Text.extend({
  addKeyboardShortcuts() {
    return {
      Space: () => {
        this.editor.commands.insertContent("\u00A0");
        return true;
      },
    };
  },
});

const CustomParagraph = Paragraph.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      marginBottom: {
        default: "2px",
        parseHTML: (element) => element.style.marginBottom || "2px",
        renderHTML: () => {
          return {
            style: `margin: 2px 0px; min-height: 20px`,
          };
        },
      },
    };
  },
});

const CustomTable = Table.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      borderStyle: {
        default: "width: 100%",
        renderHTML: () => {
          return {
            style: "width: 100%",
          };
        },
      },
    };
  },
});

const CustomTableCell = TableCell.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      borderStyle: {
        default: "2px solid #ced4da",
        parseHTML: (element) =>
          element.style.borderStyle || "2px solid #ced4da",
        renderHTML: (attributes) => {
          return {
            style: `border: ${attributes.borderStyle}`,
          };
        },
      },
      backgroundColor: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-background-color"),
        renderHTML: (attributes) => {
          return {
            "data-background-color": attributes.backgroundColor,
            style: `background-color: ${attributes.backgroundColor}`,
          };
        },
      },
      style: {
        default: "height: 24px; padding: 1px 3px",
      },
    };
  },
});

const CustomTableRow = TableRow.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      style: {
        default: "height: 24px",
      },
    };
  },
});

export default {
  name: "TextEditor",
  components: {
    EditorContent,
    ColorPicker,
    AddImageModal,
  },
  props: {
    modelValue: {
      type: String,
      default: "",
    },
    cancel: {
      type: Boolean,
      default: false,
    },
    uniqueIdForThisEdit: {
      type: Number,
      default: Date.now(),
    },
    usaAdicionales: {
      type: Boolean,
      default: false,
    },
    labelVariables: {
      type: String,
      default: "",
    },
    usaDictado: {
      type: Boolean,
      default: false,
    },
    hideImage: {
      type: Boolean,
      default: false,
    },
    canUseEditor: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      hoveredRow: -1,
      hoveredCol: -1,
      loaded: false,
      rules: rules,
      microIcon: enums.icons.MICRO,
      pencilIcon: enums.icons.EDIT_CIRCLE,
      boldIcon: enums.icons.FORMAT_BOLD,
      italicIcon: enums.icons.FORMAT_ITALIC,
      underlineIcon: enums.icons.FORMAT_UNDERLINE,
      strikeIcon: enums.icons.FORMAT_STRIKE,
      clearFormatIcon: enums.icons.FORMAT_CLEAR,
      previewIcon: enums.icons.SEE,
      undoIcon: enums.icons.UNDO,
      redoIcon: enums.icons.REDO,
      imageIcon: enums.icons.ADD_IMAGE,
      olIcon: enums.icons.OL,
      ulIcon: enums.icons.UL,
      quoteIcon: enums.icons.QUOTE,
      minusIcon: enums.icons.REMOVE,
      listCheckboxIcon: enums.icons.LIST_CHECKBOX,
      alignLeftIcon: enums.icons.ALIGN_LEFT,
      alignRightIcon: enums.icons.ALIGN_RIGHT,
      alignCenterIcon: enums.icons.ALIGN_CENTER,
      alignJustifyIcon: enums.icons.ALIGN_JUSTIFY,
      addTableIcon: enums.icons.TABLE_ADD,
      colorTextIcon: enums.icons.COLOR_TEXT,
      colorFillIcon: enums.icons.COLOR_FILL,
      linkIcon: enums.icons.LINK,
      subMenuIcon: enums.icons.CHEVRON_RIGHT,
      indentIcon: enums.icons.INDENT,
      outdentIcon: enums.icons.OUTDENT,
      uniqueId: this.uniqueIdForThisEdit,
      urlImg: "",
      editor: null,
      variableSelected: null,
      showInputImage: false,
      isLoading: false,
      imgUploadedAlto: "",
      imgUploadedAncho: "",
      file: null,
      displayPreview: false,
      myImage: null,
      headingSelected: null,
      showColorText: false,
      showColorFill: false,
      showMenuTables: false,
      menuColorCelda: false,
      menuBordesCelda: false,
      menuListas: false,
      menuAlinear: false,
      hasTable: false,
      menuTablasGlobal: false,
      menuTablasInsert: false,
      qRow: 0,
      qCol: 0,
      withHeaderRow: false,
      headingItems: [
        { id: "p", value: "Párrafo", level: 0, style: { "font-size": "14px" } },
        {
          id: "h6",
          value: "Título 6",
          level: 6,
          style: { "font-size": "16px" },
        },
        {
          id: "h5",
          value: "Título 5",
          level: 5,
          style: { "font-size": "18px" },
        },
        {
          id: "h4",
          value: "Título 4",
          level: 4,
          style: { "font-size": "20px" },
        },
        {
          id: "h3",
          value: "Título 3",
          level: 3,
          style: { "font-size": "22px" },
        },
        {
          id: "h2",
          value: "Título 2",
          level: 2,
          style: { "font-size": "24px" },
        },
        {
          id: "h1",
          value: "Título 1",
          level: 1,
          style: { "font-size": "26px" },
        },
      ],
      borderStyles: [
        { name: "Linea simple", value: "groove" },
        { name: "Puntos", value: "dotted" },
        { name: "Linea intermitente", value: "dashed" },
        { name: "Linea sólida", value: "solid" },
        { name: "Sin borde", value: "none" },
      ],
      asIdListTmp: [],

    };
  },
  mounted() {
    this.editor = new Editor({
      content: this.modelValue,
      extensions: [
        StarterKit.configure({
          paragraph: false,
          text: false,
        }),
        CustomParagraph,
        CustomText,
        Underline,
        TextStyle,
        Color,
        Highlight.configure({ multicolor: true }),
        TaskList,
        TaskItem.configure({
          nested: true,
        }),
        TextAlign.configure({
          types: ["heading", "paragraph"],
        }),
        ResizableImage.configure({
          allowBase64: true,
          inline: true,
        }),
        CustomTable.configure({
          resizable: true,
        }),
        CustomTableRow,
        TableHeader,
        CustomTableCell,
        Link.configure({
          openOnClick: true,
        }),
        Indent.configure({
          types: ["listItem", "paragraph"],
          minLevel: 0,
          maxLevel: 8,
        }),
      ],
      onUpdate: () => {
        this.$emit("update:modelValue", this.editor.getHTML());
      },
    });
    setTimeout(() => (this.loaded = true), 1000);
  },
  computed: {
    getContent() {
      return this.editor?.getHTML();
    },
  },
  watch: {
    modelValue(val) {
      if (this.editor.getHTML() === val) return;
      this.editor.commands.setContent(val, false);
    },
    cancel(val) {
      if (val) {
        this.cancelTextEditor();
      }
    },
  },
  methods: {
    ...mapActions({
      deleteImgUploaded: "shared/deleteImgUploaded",
    }),
    closeMenu(e) {
      if (!e.relatedTarget || !e.relatedTarget.closest(".v-menu__content")) {
        this.showColorText = false;
        this.showColorFill = false;
      }
    },
    resaltarFilaColumna(rowIndex, colIndex) {
      this.hoveredRow = rowIndex;
      this.hoveredCol = colIndex;
      this.qRow = colIndex + 1;
      this.qCol = rowIndex + 1;
    },
    isHovered(rowIndex, colIndex) {
      return rowIndex <= this.hoveredRow && colIndex <= this.hoveredCol;
    },
    onAddTableClick() {
      this.hasTable = this.editor.isActive("table");
    },
    onClickInsertarTabla() {
      this.editor
        .chain()
        .focus()
        .insertTable({
          rows: this.qRow,
          cols: this.qCol,
          withHeaderRow: !!this.withHeaderRow,
        })
        .run();
    },
    setLink() {
      const previousUrl = this.editor.getAttributes("link").href;
      const url = window.prompt(
        "Agregar URL (ej. https://ejemplo.com)",
        previousUrl
      );

      if (url === null) return;

      if (url === "") {
        this.editor.chain().focus().extendMarkRange("link").unsetLink().run();
        return;
      }

      this.editor
        .chain()
        .focus()
        .extendMarkRange("link")
        .setLink({ href: url })
        .run();
    },
    selectItem(item) {
      this.headingSelected = item.raw;
      this.onChangeHeading();
    },
    onClickEditor() {
      if (this.editor.isActive("paragraph")) {
        this.headingSelected = { id: "p", value: "Párrafo", level: 0 };
        return;
      }
      if (this.editor.isActive("heading", { level: 1 })) {
        this.headingSelected = { id: "h1", value: "Título 1", level: 1 };
        return;
      }
      if (this.editor.isActive("heading", { level: 2 })) {
        this.headingSelected = { id: "h2", value: "Título 2", level: 2 };
        return;
      }
      if (this.editor.isActive("heading", { level: 3 })) {
        this.headingSelected = { id: "h3", value: "Título 3", level: 3 };
        return;
      }
      if (this.editor.isActive("heading", { level: 4 })) {
        this.headingSelected = { id: "h4", value: "Título 4", level: 4 };
        return;
      }
      if (this.editor.isActive("heading", { level: 5 })) {
        this.headingSelected = { id: "h5", value: "Título 5", level: 5 };
        return;
      }
      if (this.editor.isActive("heading", { level: 6 })) {
        this.headingSelected = { id: "h6", value: "Título 6", level: 6 };
        return;
      }
    },
    onClickColorText() {
      if (this.editor.isActive("textStyle")) {
        this.editor.chain().focus().unsetColor().run();
        this.showColorText = false;
      }
    },
    onClickColorFill() {
      if (this.editor.isActive("highlight")) {
        this.editor.chain().focus().unsetHighlight().run();
        this.showColorText = false;
      }
    },
    onColorSelect(colorSelected, from) {
      if (from === "colorText") {
        this.editor.chain().focus().setColor(colorSelected).run();
        this.showColorText = false;
      } else if (from === "cellBgColor") {
        this.editor
          .chain()
          .focus()
          .setCellAttribute("backgroundColor", colorSelected)
          .run();
        setTimeout(() => {
          this.menuColorCelda = false;
          this.menuTablasGlobal = false;
        }, 25);
      } else {
        this.editor
          .chain()
          .focus()
          .toggleHighlight({ color: colorSelected })
          .run();
        this.showColorFill = false;
      }
    },
    onChangeHeading() {
      if (!this.headingSelected) return;
      if (this.headingSelected.level == 0) {
        this.editor.chain().focus().setParagraph().run();
        return;
      } else {
        this.editor
          .chain()
          .focus()
          .setHeading({ level: this.headingSelected.level })
          .run();
        return;
      }
    },
    insertImageFromUrl(url) {
      this.editor.chain().focus().setImage({ src: url }).run();
      this.showInputImage = false;
    },
    async onUpdatedFile(archivoSubidoId) {
      this.asIdListTmp.push(archivoSubidoId);
      let imgUploaded = await axios.get(
        `api/archivo/get-img?asId=${archivoSubidoId}`
      );
      this.urlImg = `${imgUploaded.config.baseURL}${imgUploaded.config.url}`;
      if (this.urlImg) {
        this.editor.chain().focus().setImage({ src: this.urlImg }).run();
      }
      this.showInputImage = false;
      this.isLoading = false;
    },
    onDictado() {
      this.$emit("onDictado");
    },
    onChangeWithHeaderTable() {
      setTimeout(() => {
        this.menuTablasInsert = true;
      }, 20);
    },
    onChangecolorTable() {
      setTimeout(() => {
        this.menuTablasGlobal = true;
        this.menuColorCelda = true;
      }, 10);
    },
    openModalImage() {
      this.showInputImage = true;
    },
    cancelTextEditor() {
      if (this.asIdListTmp.length) {
        this.asIdListTmp.forEach(async (as) => {
          const res = await this.deleteImgUploaded(as);
          if (!res) {
            setTimeout(async () => {
              await this.deleteImgUploaded(as);
            }, 1000);
          }
        });
      }
    },
    toggleBold() {
      this.editor.chain().focus().toggleBold().run();
    },
    toggleItalic() {
      this.editor.chain().focus().toggleItalic().run();
    },
    toggleUnderline() {
      this.editor.chain().focus().toggleUnderline().run();
    },
    toggleStrike() {
      this.editor.chain().focus().toggleStrike().run();
    },
    setTextAlign(align) {
      this.editor.chain().focus().setTextAlign(align).run();
    },
    indent() {
      this.editor.chain().focus().indent().run();
    },
    outdent() {
      this.editor.chain().focus().outdent().run();
    },
    toggleBulletList() {
      this.editor.chain().focus().toggleBulletList().run();
    },
    toggleOrderedList() {
      this.editor.chain().focus().toggleOrderedList().run();
    },
    toggleTaskList() {
      this.editor.chain().focus().toggleTaskList().run();
    },
    setHorizontalRule() {
      this.editor.chain().focus().setHorizontalRule().run();
    },
    undo() {
      this.editor.chain().focus().undo().run();
    },
    redo() {
      this.editor.chain().focus().redo().run();
    },
    unsetAllMarks() {
      this.editor.chain().focus().unsetAllMarks().run();
    },
  },
  beforeUnmount() {
    this.editor.destroy();
  },
};
</script>

<style lang="scss">
.text-editor-wrapper {
  border: 1px solid #c7c7c7;
  border-radius: 4px;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 16px;
  padding: 8px;
}
.tiptap {
  min-height: 60vh;
  max-height: 80vh;
  overflow-y: auto;
  padding: 10px 10px;
  p {
    margin-bottom: 2px;
  }
  > * + * {
    margin-top: 0.1em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 0.8;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0d0d0d;
    color: #fff;
    font-family: "JetBrainsMono", monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }
  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0d0d0d, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0d0d0d, 0.1);
    margin: 2rem 0;
  }
  .tableWrapper {
    padding: 1rem 0;
    overflow-x: auto;
  }

  .resize-cursor {
    cursor: ew-resize;
    cursor: col-resize;
  }
}

ul[data-type="taskList"] {
  list-style: none;
  padding: 0;

  p {
    margin: 0;
  }

  li {
    display: flex;

    > label {
      flex: 0 0 auto;
      margin-right: 0.5rem;
      user-select: none;
    }

    > div {
      flex: 1 1 auto;
    }

    ul li,
    ol li {
      display: list-item;
    }

    ul[data-type="taskList"] > li {
      display: flex;
    }
  }
}
mark {
  background-image: none;
  padding: 0.125em 0;
  border-radius: 0.25em;
  box-decoration-break: clone;
}
.tiptap {
  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    margin: 0;
    overflow: hidden;

    td,
    th {
      min-width: 10em;
      border: 2px solid #ced4da;
      padding: 3px 5px;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;

      > * {
        margin-bottom: 0;
      }
    }

    th {
      font-weight: bold;
      text-align: left;
      background-color: #f1f3f5;
    }

    .selectedCell:after {
      z-index: 2;
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(200, 200, 255, 0.4);
      pointer-events: none;
    }

    .column-resize-handle {
      position: absolute;
      right: -2px;
      top: 0;
      bottom: -2px;
      width: 4px;
      background-color: #adf;
      pointer-events: none;
    }

    p {
      margin: 0;
    }
  }
}

.tableWrapper {
  padding: 1rem 0;
  overflow-x: auto;
}

.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}
.toolbar {
  background-color: #f7f7f7;
  border-radius: 4px;
  padding: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.heading-combo {
  width: 120px;
}

.is-active {
  background-color: rgba(201, 201, 201, 0.66);
  font-weight: bold;
}

.cuadricula {
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  grid-template-rows: repeat(7, 1fr);
  gap: 2px;
}

.cuadrado {
  width: 18px;
  height: 18px;
  border: 1px solid #b0bec5;
  border-radius: 2px;
  cursor: pointer;
}

.cuadrado.hovered {
  background-color: #90caf9;
}

.preview-container {
  border: 1px solid #c7c7c7;
  border-radius: 4px;
  padding: 16px;
  background-color: #fff;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
  margin-top: 16px;
}

.menu-card {
  border: 1px solid #c7c7c7;
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}

.menu-row {
  justify-content: center;
}

.li-disabled {
  pointer-events: none;
  color: #ccc;
}
</style>
