import axios from "axios";

export default {
  async getManualUsuarioByGrupoAndPermiso(context, payload) {
    const response = await axios.get(
      `api/manualUsuario/get-manual-usuario?grupoUsuId=${payload.grupoUsuId}&permisoId=${payload.permisoId}`
    );
    return response;
  },
  async saveManualUsuario(context, payload) {
    const response = await axios.post(
      "api/manualUsuario/save-manual-usuario",
      payload
    );
    return response;
  },
};
