<template>
  <v-container>
    <PageHeader :title="pageTitle" :path="goToPath" />
    <v-row>
      <v-col
        :cols="isMobile ? 12 : 3"
        class="py-0"
        :class="isMobile ? 'pr-0' : ''"
        v-if="userAdmin"
      >
        <v-select
          label="Grupos de usuarios"
          v-model="selectedGrupo"
          variant="outlined"
          density="compact"
          :items="gruposList"
          @update:model-value="loadPermisos(), loadContent()"
          return-object
          item-value="id"
          item-title="value"
          clearable
          id="v-select-gruposList"
        ></v-select>
      </v-col>
      <v-col
        :cols="isMobile ? 12 : 3"
        class="py-0"
        :class="isMobile ? 'pr-0' : ''"
      >
        <v-autocomplete
          v-model="permisoSelected"
          :label="userAdmin ? 'Permiso' : 'Módulo'"
          variant="outlined"
          density="compact"
          :loading="isLoadingItems"
          :items="permisosList"
          @update:model-value="loadContent()"
          item-title="permisoNombre"
          item-value="permisoId"
          id="vAutocomplete-edit-permiso"
        ></v-autocomplete>
      </v-col>
      <v-col
        :cols="isMobile ? 12 : 3"
        class="py-0"
        :class="isMobile ? 'pr-0' : ''"
      >
        <v-select
          label="Tipo manual"
          v-model="selectedTipoManual"
          variant="outlined"
          density="compact"
          :items="tiposManual"
          v-if="displayContentApi || userAdmin"
          @update:model-value="loadContent()"
          item-value="id"
          item-title="value"
          id="v-select-tiposManual"
        ></v-select>
      </v-col>
      <v-col
        :cols="isMobile ? 10 : userAdmin ? 3 : 6"
        :class="isMobile ? 'pl-0' : 'pt-0'"
      >
        <v-btn
          v-if="userAdmin"
          color="primary"
          variant="flat"
          id="v-btn-actualizar"
          :disabled="!permisoSelected || !selectedGrupo || isLoadingItems"
          @click="saveContent"
          class="to-right"
          >Guardar</v-btn
        >
        <v-btn
          color="primary"
          variant="flat"
          id="v-btn-download"
          :disabled="!permisoSelected || isLoadingItems"
          @click="downloadPDF()"
          :class="(userAdmin ? 'mr-2' : '') + ' to-right'"
          >Descargar</v-btn
        >
        <!-- Este div es utilizado para renderizar el contenido HTML, está oculto -->
        <div
          id="pdf-content"
          v-html="editorContent"
          style="position: absolute; top: -9999px; left: -9999px"
        ></div>
      </v-col>
    </v-row>
    <div v-if="permisoSelected != null">
      <TextEditor
        v-model="editorContent"
        :canUseEditor="userAdmin"
        @update:modelValue="editorContent = $event"
      />
    </div>
  </v-container>
</template>

<script>
import TextEditor from "@/components/shared/TextEditor.vue";
import PageHeader from "@/components/layout/PageHeader.vue";
import enums from "@/utils/enums/index";
import { mapActions, mapGetters } from "vuex";
import html2pdf from "html2pdf.js";

export default {
  name: "ManualesUsuario",
  components: {
    TextEditor,
    PageHeader,
  },
  data() {
    return {
      editorContent: "",
      editorId: null,
      pageTitle: enums.permises.MANUALUSUARIO.name,
      goToPath: enums.permises.HOME.path,
      selectedGrupo: null,
      gruposList: [],
      permisosList: [],
      permisosListItems: [],
      permisoSelected: null,
      isLoadingItems: false,
      userAdmin: false,
      selectedTipoManual: 1,
      tiposManual: [
        {
          id: 1,
          value: "Sistema",
        },
        {
          id: 2,
          value: "Integración API",
        },
      ],
      contentApi: null,
      displayContentApi: false,
    };
  },
  computed: {
    ...mapGetters({
      isMobile: "menu/isMobile",
    }),
  },
  created() {
    this.getDataView();
  },
  methods: {
    ...mapActions({
      getGruposUsuarios: "taxonomy/getGruposUsuarios",
      getPermisosByUserGroup: "permisos/getPermisosByUserGroup",
      saveManualUsuario: "manualUsuario/saveManualUsuario",
      getManualUsuarioByGrupoAndPermiso:
        "manualUsuario/getManualUsuarioByGrupoAndPermiso",
      getFiltersByUser: "solicitud/getFiltersByUser",
      setAlert: "user/setAlert",
    }),
    async getDataView() {
      this.gruposList = await this.getGruposUsuarios();
      const filtersByUser = await this.getFiltersByUser();
      if (filtersByUser.esAdmin) this.userAdmin = true;
      else if (filtersByUser.financiadorId != null)
        this.selectedGrupo = this.gruposList.find((x) => x.id == 2);
      else if (filtersByUser.emisorId != null)
        this.selectedGrupo = this.gruposList.find((x) => x.id == 3);
      else if (filtersByUser.preId != null)
        this.selectedGrupo = this.gruposList.find((x) => x.id == 4);
      else if (filtersByUser.farmaciaId != null)
        this.selectedGrupo = this.gruposList.find((x) => x.id == 5);
      else console.error("El usuario no posee un grupo asignado.");

      this.loadPermisos();
    },
    async loadPermisos() {
      if (!this.selectedGrupo) return;
      try {
        this.isLoadingItems = true;
        this.permisosList = await this.getPermisosByUserGroup(
          this.selectedGrupo.id
        );
        this.permisosList = this.permisosList.filter(
          (x) => x.permisoCodigo != enums.permises.MANUALUSUARIO.code
        );
        if (this.permisosList.length === 1) {
          this.permisoSelected = this.permisosList[0].permisoId;
          this.loadContent();
        }
      } catch (error) {
        console.error(error);
      }
      this.isLoadingItems = false;
    },
    async loadContent() {
      try {
        if (this.permisoSelected != null && this.selectedGrupo != null) {
          const response = await this.getManualUsuarioByGrupoAndPermiso({
            grupoUsuId: this.selectedGrupo?.id,
            permisoId: this.permisoSelected,
          });
          if (response.status === 200) {
            this.editorId = response.data.manualUsuarioId;
            if (this.selectedTipoManual == 1)
              this.editorContent = response.data.manualUsuarioTexto;
            // Sistema
            else this.editorContent = response.data.manualUsuarioTextoApi; // API
            this.contentApi = response.data.manualUsuarioTextoApi ?? "";
            this.displayContentApi = this.contentApi.length > 0;
          } else {
            this.editorId = null;
            this.editorContent = "";
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    async saveContent() {
      this.isLoadingItems = true;
      try {
        const data = {
          manualUsuarioId: this.editorId,
          manualUsuarioTexto:
            this.selectedTipoManual == 1 ? this.editorContent : null,
          manualUsuarioTextoApi:
            this.selectedTipoManual == 2 ? this.editorContent : null,
          grupoUsuId: this.selectedGrupo?.id,
          permisoId: this.permisoSelected,
        };
        const response = await this.saveManualUsuario(data);
        if (response.status === 200) {
          this.setAlert({
            type: "success",
            message: "Manual de usuario guardado con éxito.",
          });
        }
      } catch (error) {
        console.error(error);
      }
      this.isLoadingItems = false;
    },
    async downloadPDF() {
      try {
        this.$nextTick(() => {
          // Seleccionamos el contenido HTML que queremos exportar
          const element = document.getElementById("pdf-content").innerHTML;

          // Opciones de configuración para html2pdf
          const options = {
            margin: 1,
            filename: "Manual_de_usuario.pdf",
            image: { type: "jpeg", quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
          };

          // Generar el PDF a partir del HTML
          html2pdf().set(options).from(element).save();
        });
      } catch (error) {
        console.error("Error generating PDF from HTML:", error.message);
      }
    },
  },
};
</script>
